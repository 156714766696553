@import url('https://fonts.googleapis.com/css2?family=K2D:wght@400;600&display=swap');


.booking-container{
    height: 100%;
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.booking-top{
    height: 50vh;
    width: 110%;
    background-image: url(../../assets/Strathcona-Side.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-blend-mode: overlay;
    background-color: #ffffffbb;
    display: flex;
    align-items: center;
    padding: 50px;
    justify-content: center;
    position: relative;
    font-family: 'K2D', sans-serif;
}
.booking-top h1{
    color: var(--darkgreen);
    font-size: 3.5rem;
    width: 80%;
}
.reservation-info{
    height: 20vh;
    width: 100%;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background: var(--lightgreen);
}
form{
    width: 100%;
}
.reservation-heading{
    width: 100%;
    text-align: center;
    background-color: var(--lightgreen);
    padding: 3rem;
}
.reservation-heading h2{
    font-size: 2.5rem;
}
.reservation-info input{
    
    font-size: 1em;
}
.reservation-info label{
    font-size: 1.5em;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.reservation-info input, select, textarea{
    color: var(--darkgreen);
}
.date-selector{
    height: 20vh;
    width: 100%;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    background: var(--lightgreen);
}
.date-selector input{
    padding: 10px 40px;
    font-size: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}
.date-selector select{
    padding: 10px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.8em;
}
.date-selector label{
    font-size: 1.5em;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.date-selector label input{
    align-self: flex-start;
}
.date-selector input, select, textarea{
    color: var(--darkgreen);
}
.booking-submit{
    background-color: var(--lightgreen);
    height: 100%;
    width: 100%;
    text-align: center;
    margin-bottom: -1px;
}
.booking-submit input{
    padding: 10px 50px;
    border: none;
    background-color: var(--lightblue);
    margin-bottom: 40px;
    border-radius: 10px;
    font-size: 1em;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.booking-submit input:hover{
    transform: scale(1.1);
}
.booking-submit h3{
    font-size: 1.5em;
    padding: 20px;
    padding-bottom: 100px;
}

.booking-bottom{
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.custom-shape-divider-top-1663457781 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1663457781 svg {
    position: relative;
    display: block;
    width: calc(150% + 1.3px);
    height: 50px;
}

.custom-shape-divider-top-1663457781 .shape-fill {
    fill: #CDD5C7;
}
.booking-left{
    height: 100%;
    width: 50%;
    padding: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 100px;
}
.booking-left h2{
    font-size: 2.5em;
    padding-bottom: 10px;
}
.booking-left h3{
    font-size: 1.3em;
    padding-bottom: 10px;
}
.booking-left h4{
    font-size: 1.5rem;
    margin-bottom: 1rem;
}
.booking-left h4 span{
    font-size: 1rem;
}
.booking-left h2 span{
    color: var(--lightblue);
}
.booking-left ul li{
    text-align: left;
    list-style-type:disc;
    font-weight: 500;
}
.booking-left .booking-deluxe{
    text-align: start;
}
.booking-left .booking-superior{
    text-align: start;
}
.booking-right{
    height: 100%;
    width: 50%;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
}
.booking-right img{
    width: 300px;
    height: 300px;
    border-radius: 10px;
}
.booking-right h4{
    font-size: 1.5em;
    padding: 10px;
}
.image-kitchen{
    padding: 20px;
    border-radius: 25px;
    background-color: #cdd5c771;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.068);
}
.image-dinning{
    padding: 20px;
    border-radius: 25px;
    background-color: #cdd5c771;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.068);
}
.image-bath{
    padding: 20px;
    border-radius: 25px;
    background-color: #cdd5c771;
    
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.068);
}

