@import url('https://fonts.googleapis.com/css2?family=K2D:wght@400;600&display=swap');

.hero-container{
    height: 100vh;
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:#C5CDC0;
    text-transform: capitalize;
    font-family: 'K2D', sans-serif;
    
}
.hero-content{
    height: 100%;
    width: 110%;
    background-image: url(../../assets/Hero-Background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    display: flex;
    position: relative;
    z-index: 0;
}
.hero-info{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    gap: 40px;
    position: relative;
}
.hero-info h2{
    font-size: 4em;
    font-weight: 400;
    padding: 20px;
}
.hero-info h1{
    font-size: 6em;
    padding: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 50px;
}
.hero-info button{
    width: 200px;
    padding: 15px 30px;
    font-size: 1em;
    border-radius: 10px;
    border: none;
    background-color: var(--lightblue); 
    color: var(--white);
    transition: 0.3s ease-in-out;
    font-size: 1.2em;
    font-weight: 100;
    
    
}
.hero-info button:hover{
    cursor: pointer;
    transform: scale(1.1);
}
.hero-info a{
    text-decoration: none;
}

.custom-shape-divider-bottom-1662165651 {
    position: absolute;
    bottom: 50%;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1662165651 svg {
    position: relative;
    display: block;
    width: calc(150% + 1.3px);
    height: 50px;
}

.custom-shape-divider-bottom-1662165651 .shape-fill {
    fill: #FFFFFF;
}