@import url('https://fonts.googleapis.com/css2?family=K2D:wght@400;600&display=swap');

.banner-container{
    height: 50vh;
    width: 110vw;
    background-image: url(../../../assets/about-us.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0 0 10px 10px var(--white) inset;
    font-family: 'K2D', sans-serif;
    
}
.banner-blur{
    
    background-image: url(../../../assets/about-us.jpg);
    background-position: center;
    background-repeat: no-repeat;
    height: 50%;
    width: 50%;
    filter: blur(5px);
}
.banner-content{
    width: 100%;
    height: 100%;
    filter: none;
    z-index: 1;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    
}
.banner-content h1{
    color: var(--white);
    font-size: 4.5rem ;
    font-weight: 300;
    animation: banner-fade 2s ease-in;
}
.banner-content h2{
    color: var(--white);
    font-size: 2.5em;
    font-weight: 300;
    animation: banner-fade 2s ease-in;
}

@keyframes banner-fade {
    0% {opacity: 0;}
    100% {opacity: 1;}
}