.map-container{
    height: 100%;
    width: 100vw;
    background: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 50px;
    position: relative;
}
.map-top{
height: 100%;
width: 100%;
padding: 30px;
display: flex;
justify-content: center;
margin-top: 20px;
margin-bottom: 20px;
}
.map-top h3{
    width: 80%;
    font-size: 2em;
    padding: 20px;
}
.map-bottom{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}
.map-left{
    width: 50%;
    padding: 20px;
    background: #3f91d45a;
    display: flex;
    justify-content: center;
    align-items: center;
}
.map-left iframe{
    border-radius: 10px;
}
.map-right{
    width: 50%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.map-right h2{
    padding: 0;
    margin: 0;
    font-size: 2.5em;
    font-weight: 500;
}
.map-right h2 span{
    color: var(--lightblue);
}
.map-right .sights-gallery{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.sights-gallery .sights-falls{
    height: 200px;
    width: 200px;
    background-image: url(../../assets/Marina.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    border-radius: 10px;
    box-shadow: 0 0 10px 10px var(--white) inset;
}
.sights-gallery .sights-fw{
    height: 200px;
    width: 200px;
    background-image: url(../../assets/FortWilliam.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center; 
    border-radius: 10px;
    box-shadow: 0 0 10px 10px var(--white) inset;
}
.sights-gallery .sights-marina{
    height: 200px;
    width: 200px;
    background-image: url(../../assets/BoulevardLake.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    border-radius: 10px;
    box-shadow: 0 0 10px 10px var(--white) inset;
}
.map-right button{
    width: 200px;
    height: 50px;
    background: none;
    font-size: 16px;
    font-weight: 400;
    border-radius: 10px;
    margin-top: 1rem;
    border: 1px solid #1D2911;
    transition: 0.3s ease-in-out;
    margin-bottom: 1rem;
    cursor: pointer;
}
.map-right button:hover{
    transform: scale(1.1);
}
.map-right-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.map-right p{
margin-top: 1rem;
font-size: 1.3em;
font-weight: 600;
width: 80%;
}

.custom-shape-divider-bottom-1662348907 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    margin-bottom: -1px;
}

.custom-shape-divider-bottom-1662348907 svg {
    position: relative;
    display: block;
    width: calc(150% + 1.3px);
    height: 50px;
}

.custom-shape-divider-bottom-1662348907 .shape-fill {
    fill: var(--lightgreen);
}